import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Button,
    OverflowMenu,
    OverflowMenuItem,
    DataTableSkeleton,
    Pagination
} from 'carbon-components-react';
import { Download32 } from '@carbon/icons-react';
// import { BlobProvider } from '@react-pdf/renderer';
// import ApplicantPdf from '../util/ApplicantPdf';
import { useContext } from 'react';
import { NssspContext } from '../gatsby-theme-carbon/components/Layout';
import { fetchAuthenticatedContent, UserContext } from '@parallelpublicworks/entitysync'
// import getLorsLink from '../util/getLorsLink';
import parseAnswerAttached from '../util/parseAnswerAttached';
import { navigate } from 'gatsby';

const REACT_APP_ENTITYSYNC_BASE_URL = process.env.REACT_APP_ENTITYSYNC_BASE_URL;

const headers = [
    {
        key: 'name',
        header: 'Name',
    },
    {
        key: 'field_biosketch',
        header: 'field_biosketch',
    },
    {
        key: 'field_letters_of_recommendation',
        header: 'field_letters_of_recommendation',
    },
];

function ApplicationTable({ location }) {
    
    const userContext = useContext(UserContext)
    const userState = userContext && typeof userContext[0] !== 'undefined' ? userContext[0] : null
    
    const { user } = useContext(NssspContext);

    // const [downloadData, setDownloadData] = React.useState();
    
    // const [loadingLor, setLoadingLor] = React.useState(false);

    const [loading, setLoading] = useState(false)
    const [paginationOptions, setPaginationOptions] = React.useState({ currentPage: 1, pageSize: 50 });
    const [applicantAnswers, setApplicantAnswers] = useState([]);

    const [ratings, setRatings] = useState([])
    const [loadingRatings, setLoadingRatings] = useState(false)

    useEffect(() => {
        const page = new URLSearchParams(location.search).get("page");
        const limit = new URLSearchParams(location.search).get("limit") || 50;
        if(!page) return navigate('/reviewer?page=1&limit=50');
        else setPaginationOptions({ ...paginationOptions, currentPage: +page, pageSize: +limit });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if(user.id) {
        fetchApplicants();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id, paginationOptions.pageSize])
  
    async function fetchApplicants(args = {}) {
        
        let { urlPagination, currentPage, pageSize } = args;

        if(loading) return;

        const page = new URLSearchParams(location.search).get("page");
        const _pageSize = new URLSearchParams(location.search).get("limit");
        
        if(!currentPage && page) {
            currentPage = +page;
        } else if(!currentPage) {
            currentPage = paginationOptions.currentPage;
        }
        if(!pageSize && _pageSize) {
            pageSize = +_pageSize;
        } else if(!pageSize) {
            pageSize = paginationOptions.pageSize;
        }

        let endpoint = '';
        if(!urlPagination) {
            endpoint = 'node/answers/'
            endpoint += `?filter[field_submitted][value]=1`
            endpoint += `&include=field_biosketch,field_letters_of_recommendation`

            endpoint += `&page[offset]=${(+currentPage - 1) * pageSize}`
            endpoint += `&page[limit]=${pageSize}`

            endpoint += `&sort=field_first_name,field_last_name`
        } else {
            endpoint = urlPagination.href.replace(`${REACT_APP_ENTITYSYNC_BASE_URL}/jsonapi/`, '');
        }
    
        setLoading(true)
        
        const resp = await fetchAuthenticatedContent(userState.auth, userContext[1], endpoint, 'GET');
        
        setLoading(false)
        
        if(resp && resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
            
            setPaginationOptions({ ...paginationOptions, currentPage: currentPage ? currentPage : 1, count: resp.meta.count, links: resp.links, pageSize: pageSize });
            let applicants = parseAnswerAttached(resp);
            setApplicantAnswers(applicants)
            fetchRatings(applicants);
        }
        
    }
  
    async function fetchRatings(applicants) {
  
      if(loadingRatings) return;

      let totalCount = applicants.length;
  
      let endpoint = `node/applicant_rate/`
      endpoint += `?filter[uid.id][value]=${user.id}`
      endpoint += `&include=field_applicant_answer`
  
      setLoadingRatings(true)
      
      let slices = 1;
      if(totalCount >= 25) {
        slices = totalCount / 25
          let slicesRound = Math.floor(slices);
          if(slices - slicesRound > 0) {
            slices = slicesRound + 1;
          } else {
            slices = slicesRound;
          }
      }

      let promises = [];
      new Array(slices).fill('1').forEach((_, index) => {
          
        let start = index * 25;
        let end = start + 25;

        if(index + 1 === slices) end = totalCount;

        let endpointComplement = endpoint;
        applicants.slice(start, end).forEach((applicant, index) => {
            endpointComplement += `&filter[name-filter][condition][path]=field_applicant_answer.id`
            endpointComplement += `&filter[name-filter][condition][operator]=IN`
            endpointComplement += `&filter[name-filter][condition][value][${index + 1}]=${applicant.id}`
        })
        
        promises = [...promises, fetchAuthenticatedContent(userState.auth, userContext[1], endpointComplement, 'GET')];
      })
      
      let responses = await Promise.all(promises);

      let ratings = [];
      responses.forEach(resp => {
          if(resp && resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
            let _ratings = resp ? mapAnswerRelationship(resp) : []
            ratings = [...ratings, ..._ratings]
          }
      })
      
      setRatings(ratings);
      
      setLoadingRatings(false)
    }

    function mapAnswerRelationship(response) {
  
      let { data, included } = response;
      
      return data.map(rating => {
  
        let id = rating.relationships.field_applicant_answer?.data?.id;
        
        let applicantAnswer = included.find(answer => answer.id === id) || {};
  
        return {
          ...rating,
          attributes : {
            ...rating.attributes,
            field_applicant_answer : {
              ...rating.relationships?.field_applicant_answer,
              ...applicantAnswer
            }
          }
        }
      })
    }

    // async function setDownloadInfo(e, id) {
    //     e.preventDefault();
    //     // let data = applicantAnswers.find(applicant => applicant.relationships.uid.data.id === id)
        
    //     // let lorLinks = await getLorsLink(data.attributes.field_letters_of_recommendation, userState.auth, userContext)
        
    //     // setDownloadData({...data, lorLinks });
    // }

    async function downloadLor(e, lor) {

        e.preventDefault();
        
        let endpoint = `node/lor_request/${lor.id}`
        endpoint += `?include=field_letter&fields[file--file]=uri,url`

        // setLoadingLor(true)

        const resp = await fetchAuthenticatedContent(userState.auth, userContext[1], endpoint, 'GET');
        
        // setLoadingLor(false)
        
        if(resp?.included[0]?.attributes?.uri?.value) {
            let procecedLink = resp.included[0].attributes.uri.value.replace('private://', 'system/files/');
            window.open(REACT_APP_ENTITYSYNC_BASE_URL + '/' + procecedLink, "_blank");
        } else {
            // TODO: show error
        }
    }

    function downloadBioscketch(e, realtiveLink) {
        e.preventDefault();
        let processedLink = realtiveLink?.replace('private://', 'system/files/');
        window.open(REACT_APP_ENTITYSYNC_BASE_URL + '/' + processedLink, "_blank");
    }

    const onPageChange = async (e) => {

      // let urlPagination = paginationOptions.links.self;

      // if(e.page > paginationOptions.currentPage) urlPagination = paginationOptions.links.next;
      // else urlPagination = paginationOptions.links.prev;

      navigate(`/reviewer?page=${e.page}&limit=${e.pageSize}`);
      fetchApplicants({ pageSize: e.pageSize, currentPage: e.page });
    }
    
    const rows = applicantAnswers.map(item => {
        
        return {
            id: item.relationships.uid.data.id,
            name: item.attributes.field_first_name + ' ' + item.attributes.field_last_name,
            submission_date: item.created,
            field_biosketch: item.attributes.field_biosketch?.attributes?.uri?.value,
            field_letters_of_recommendation: item.attributes.field_letters_of_recommendation,
        }
    })

    if(loadingRatings || loading) return <DataTableSkeleton />

    return (
        <>
            {/* {   downloadData &&
                <BlobProvider document={<ApplicantPdf answer={downloadData} allNodeTask={allNodeTask} options={options} />}>
                    {({ blob, url, loading, error }) => {

                        if(blob) {

                            let filename = downloadData.attributes.field_first_name + '_' + downloadData.attributes.field_last_name + '_applicant.pdf';

                            let a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            
                            let url = window.URL.createObjectURL(blob);
                            a.href = url;
                            a.download = filename;
                            a.click();
                            window.URL.revokeObjectURL(url);
                            setDownloadData(undefined);
                        }

                        // TODO: Handle loading
                        return null;
                    }}
                </BlobProvider>
            } */}
            <DataTable rows={rows} headers={headers}>
                {({ rows, headers, getTableProps, getHeaderProps, getRowProps, onInputChange }) => (
                    <TableContainer>
                        <TableToolbar>
                            <TableToolbarContent>
                                <TableToolbarSearch placeholder="Filter applicants" onChange={onInputChange} />
                            </TableToolbarContent>
                        </TableToolbar>
                        <Table {...getTableProps()}>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => {
                                        
                                        if(header.key === 'field_biosketch' || header.key === 'field_letters_of_recommendation') return null;

                                        return (
                                            <TableHeader {...getHeaderProps({ header, isSortable: false })}>
                                                {header.header}
                                            </TableHeader>
                                        )
                                    })}
                                    <TableHeader>
                                        Download
                                    </TableHeader>
                                    <TableHeader>
                                        Rating
                                    </TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow {...getRowProps({ row })}>
                                        {row.cells.map((cell) => {

                                            if(cell.info.header === 'field_biosketch' || cell.info.header === 'field_letters_of_recommendation') return null;

                                            return (
                                                <TableCell key={cell.id}>{cell.value}</TableCell>
                                            )
                                        })}
                                        <TableCell align="center">
                                            <OverflowMenu renderIcon={Download32} iconDescription="Download">
                                                <OverflowMenuItem
                                                    itemText={`Applicant answers`}
                                                    requireTitle
                                                    // onClick={(e) => setDownloadInfo(e, row.id)}
                                                    />
                                                {
                                                    (() => {
                                                        let field_biosketch = row.cells.find(item => item.info.header === 'field_biosketch')?.value;
                                                        return field_biosketch &&
                                                                <OverflowMenuItem
                                                                    itemText={`Biosketch`}
                                                                    requireTitle
                                                                    onClick={(e) => downloadBioscketch(e, field_biosketch)}
                                                                    />
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        let lors = row.cells.find(item => item.info.header === 'field_letters_of_recommendation')?.value;
                                                        
                                                        return lors.map((item, index) => {
                                                            return <OverflowMenuItem
                                                                        itemText={`Letter of recommendation ${item.attributes.field_request_email}`}
                                                                        requireTitle
                                                                        onClick={(e) => downloadLor(e, item)}
                                                                        />
                                                        })
                                                    })()
                                                }
                                            </OverflowMenu>
                                        </TableCell>
                                        {
                                            (() => {
                                                
                                                let rating = ratings.find(item => item.attributes.field_applicant_answer.relationships.uid.data.id === row.id);
                                                rating = rating?.attributes?.field_rating;
                                                
                                                if(rating !== undefined && rating !== null) {

                                                    let Link = <button
                                                                className="abstained"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    navigate(`/reviewer/${row.id}?page=${paginationOptions.currentPage}&limit=${paginationOptions.pageSize}`)
                                                                }}>
                                                                    {rating === 0 ? 'Abstained' : rating}
                                                                </button>

                                                    return (
                                                        <TableCell key="rating">
                                                            {Link}
                                                        </TableCell>
                                                    )
                                                }

                                                return (
                                                    <TableCell>
                                                        <Button style={{width: '100%'}} type="button" onClick={(e) => {
                                                            e.preventDefault()
                                                            navigate(`/reviewer/${row.id}?page=${paginationOptions.currentPage}&limit=${paginationOptions.pageSize}`)
                                                        }}>
                                                            Rate now
                                                        </Button>
                                                    </TableCell>
                                                )
                                            })()
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DataTable>
            <Pagination
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                onChange={onPageChange}
                page={paginationOptions.currentPage}
                pageSize={paginationOptions.pageSize}
                pageSizes={[
                    25,
                    50
                ]}
                size="md"
                totalItems={paginationOptions.count}
            />
        </>
    )
}

ApplicationTable.propTypes = {
    allNodeTask: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}

export default ApplicationTable;
